import {
  identifierResultsEndpointAdapter,
  platformAdapter,
  PlatformResult,
  resultSchema
} from '@empathyco/x-adapter-platform';
import { Result } from '@empathyco/x-types';
export const adapter = platformAdapter;

/* Code sample about how to extend the result mapper with more fields. */

interface EmpathyDemoPlatformResult extends PlatformResult {
  brand: string;
  category: string;
  eb_sku: string;
  novelty: boolean;
  stars: string;
  season: string;
  isOutOfStock: boolean;
}

declare module '@empathyco/x-types' {
  export interface Result {
    brand: string;
    category: string;
    eb_sku: string;
    novelty: boolean;
    stars: string;
    season: string;
    isOutOfStock: boolean;
  }
}

resultSchema.$override<EmpathyDemoPlatformResult, Partial<Result>>({
  brand: 'brand',
  category: 'category',
  eb_sku: 'eb_sku',
  novelty: 'novelty',
  stars: 'stars',
  season: 'season',
  isOutOfStock: 'isOutOfStock'
});

adapter.identifierResults = identifierResultsEndpointAdapter.extends({
  endpoint:
    'https://api.{extraParams.env(.)}empathy.co/search/v1/query/{extraParams.instance}/search'
});
