<template>
  <ExtraParams :values="extraParams" />
</template>

<script lang="ts">
  import { forEach, Dictionary } from '@empathyco/x-utils';
  import Vue from 'vue';
  import { Component, Inject, Prop, Watch } from 'vue-property-decorator';
  import { ExtraParams, extraParamsXModule } from '@empathyco/x-components/extra-params';
  import { SnippetConfig, xComponentMixin } from '@empathyco/x-components';

  /**
   * Extracts the extra parameters from the {@link SnippetConfig} and syncs it with the request
   * objects of every x-module.
   *
   * @public
   */
  @Component({
    components: { ExtraParams },
    mixins: [xComponentMixin(extraParamsXModule)]
  })
  export default class CustomSnippetConfigExtraParams extends Vue {
    /**
     * It injects {@link SnippetConfig} provided by an ancestor as snippetConfig.
     *
     * @internal
     */
    @Inject('snippetConfig')
    public snippetConfig!: SnippetConfig;

    /**
     * A Dictionary where the keys are the extra param names and its values.
     *
     * @public
     */
    @Prop()
    protected values?: Dictionary<unknown>;

    protected extraParams: Dictionary<unknown> = {};

    @Watch('snippetConfig', { deep: true, immediate: true })
    syncExtraParams(snippetConfig: SnippetConfig): void {
      forEach({ ...this.values, ...snippetConfig }, (name, value) => {
        if (!this.excludedExtraParams.includes(name)) {
          this.$set(this.extraParams, name, value);
        }
      });
    }

    /**
     * Collection of properties from the snippet config to exclude from the
     * extra params object.
     *
     * @public
     */
    @Prop({
      default: (): Array<keyof SnippetConfig> => [
        'callbacks',
        'productId',
        'uiLang',
        'consent',
        'documentDirection',
        'filters',
        'isSpa',
        'queriesPreview'
      ]
    })
    protected excludedExtraParams!: Array<keyof SnippetConfig>;
  }
</script>
