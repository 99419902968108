import { XInstaller } from '@empathyco/x-components';
import Vue from 'vue';
import { FilterEntityFactory, SingleSelectModifier } from "@empathyco/x-components/facets";
import { FilterModelName } from "@empathyco/x-types";
import { installXOptions } from './x-components/plugin.options';

Vue.config.productionTip = false;

FilterEntityFactory.instance.registerModifierByFilterModelName(
  'HierarchicalFilter',
  SingleSelectModifier
);

FilterEntityFactory.instance.registerModifierByFilterModelName(
  'priceSort' as FilterModelName,
  SingleSelectModifier
);

new XInstaller(installXOptions).init();
