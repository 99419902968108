











  import {
    SnippetCallbacks,
    SnippetConfig,
    XEvent,
    XEventsTypes,
    XOn
  } from '@empathyco/x-components';
  import { DeviceDetector } from '@empathyco/x-components/device';
  import { Tagging } from '@empathyco/x-components/tagging';
  import { UrlHandler } from '@empathyco/x-components/url';
  import { Dictionary } from '@empathyco/x-utils';
  import { Component, Inject, Vue, Watch, Provide } from 'vue-property-decorator';
  import { Result } from '@empathyco/x-types';
  // eslint-disable-next-line max-len
  import CustomSnippetConfigExtraParams from './x-components/custom-snippet-config-extra-params.vue';
  import currencies from './i18n/currencies';
  import '@empathyco/x-components/design-system/deprecated-full-theme.css';
  import './design-system/tokens.scss';

  @Component({
    components: {
      DeviceDetector,
      SnippetCallbacks,
      CustomSnippetConfigExtraParams,
      Tagging,
      UrlHandler,
      MainModal: () => import('./components/custom-main-modal.vue').then(m => m.default)
    }
  })
  export default class Layer extends Vue {
    protected breakpoints: Dictionary<number> = {
      mobile: 767,
      desktop: Number.POSITIVE_INFINITY
    };
    protected isOpen = false;

    protected add2CartCallbackEvents: XEvent[] = [
      'UserClickedResultAddToCart',
      'UserClickedResultRemoveFromCart'
    ];

    public excludedExtraParams = [
      'callbacks',
      'productId',
      'searchLang',
      'consent',
      'documentDirection',
      'currency',
      'filters',
      'isSpa',
      'queriesPreview',
      'cart',
      'wishlist'
    ];

    @XOn(['UserOpenXProgrammatically', 'UserClickedOpenX'])
    open(): void {
      this.isOpen = true;
    }

    @XOn(['SnippetCallbackExecuted'])
    async callbackExecuted(payload: XEventsTypes['SnippetCallbackExecuted']): Promise<void> {
      if (this.add2CartCallbackEvents.includes(payload.event)) {
        const hasStock = await payload.callbackReturn;
        if (hasStock === null) {
          this.$x.emit('UserModifiedAdd2cart', {
            id: (payload.payload as Result).id,
            isOutOfStock: true
          });
        }
      }
    }

    @Inject('snippetConfig')
    protected snippetConfig!: SnippetConfig;

    protected get documentDirection(): string {
      return (
        document.documentElement.dir ||
        document.body.dir ||
        (this.snippetConfig.documentDirection ?? 'ltr')
      );
    }

    @Provide('currencyFormat')
    public get currencyFormat(): string {
      return currencies[this.snippetConfig.currency!];
    }

    @Watch('snippetConfig.lang')
    syncLang(lang: string): void {
      this.$setLocale(lang);
    }
  }
